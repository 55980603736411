import React, { useEffect, useState } from 'react';
import { useQuery, gql } from '@apollo/client';

import MainLayout from '@hoc/layout';
import ContactSection from '@components/contact-section/contact-section';
import MapSection from '@components/map-section/map-section';
import BreadCrumbs from '@components/bread-crumbs/bread-crumbs';
import HandleLoading from '@hoc/handle-loading/handle-loading';
import PostTile from '@components/post-tile/post-tile';
import Seo from '@components/seo/seo';
import HorizontalScrollWrapper from '@ui-components/horizontal-scroll-wrapper';

import * as s from './blog.module.scss';
import * as s_wrapper from '@assets/styles/wrapper.module.scss';
import * as s_text from '@assets/styles/text.module.scss';
import * as s_btns from '@assets/styles/btns.module.scss';


const BLOG_CATEGORIES_QUERY = gql`
    query BlogCategoriesQuery {
		categories {
			nodes {
				categoryId
				name
				description
			}
		}
    }
`;

const BLOG_POSTS_QUERY = gql`
    query BlogPostsQuery($catId: Int, $after: String) {
		posts(first: 8, after: $after, where: {orderby: {field: DATE, order: DESC}, categoryId: $catId}) {
			pageInfo{
				hasNextPage
				endCursor
			}
			nodes {
			  categories(first: 1) {
				nodes {
				  name
				}
			  }
			  post {
				smallImage {
				  sourceUrl
				}
				excerpt
			  }
			  date
			  slug
			  title
			}
		}
    }
`;


const Blog = () => {

	const [catId, setCatId] = useState(null);


    const { loading: loading_post, error: error_post, data: data_post, refetch, fetchMore } = useQuery(BLOG_POSTS_QUERY, {
		variables: {catId, after: null},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'cache-and-network',
	});

    const { loading: loading_cat, error: error_cat, data: data_cat } = useQuery(BLOG_CATEGORIES_QUERY);

	useEffect(() => {
		refetch();
	}, [catId]);

	return (
		<MainLayout>

			<Seo
				title='Blog'
				description='Leasing i wynajem długoterminowy samochodów, maszyn i urządzeń, sprzętów budowlanych. ClickLease to najszybciej rozwijający się broker leasingu na polskim rynku. W swojej ofercie mamy aż 40 firm leasingowych. To szeroki wachlarz możliwości.'
			/>

			<BreadCrumbs />

      		<section className={`${s.blog_section} ${s_wrapper.apply} ${s_wrapper.apply_height}`}>

        		<h1>Blog</h1>
				<p className={`${s_text.subtitle} ${s.subtitle}`}>
					Nasi eksperci dzielą się z Tobą darmową wiedzą o wszystkich formach finansowania.
					Jeśli jeszcze nie wiesz, czy na któreś z nich warto się zdecydować, zapoznaj się z
					artykułami będącymi esencją wiedzy dotyczącą leasingu, kredytu i ubezpieczenia, a
					także poradnikami, które przeprowadzą Cię przez zawiłe formalności.
                </p>


				<div className={s.layout}>

					<nav className={s.nav}>

						<HandleLoading loading={loading_cat} error={error_cat}>

							<HorizontalScrollWrapper classes={s.horizontal_wrapper}>

								<div className={s.item} onClick={() => setCatId(null)}>
									<div>Wszystkie</div>
									<p>
										Poznaj wszystkie artykuły naszych specjalistów z ogromnym doświadczeniem w branży finansowej.
									</p>
								</div>

								{data_cat && data_cat.categories.nodes.map((el, id) => {

									if(el.name !== 'Bez kategorii'){
										return(
											<div key={id} className={s.item} onClick={() => setCatId(el.categoryId)}>
												<div>{el.name}</div>
												<p>
													{el.description}
												</p>
											</div>
										)
									}
								})}

							</HorizontalScrollWrapper>

						</HandleLoading>

					</nav>


					<div className={s.content}>

						<HandleLoading loading={loading_post && !data_post} error={error_post}>

							{data_post && data_post.posts.nodes.map((el, id) => {
								return(
									<PostTile
										classes={s.post_tile}
										key={id}
										title={el.title}
										mainImgUrl={el.post.smallImage.sourceUrl}
										date={el.date.split('T')[0]}
										slug={el.slug}
										excerpt={el.post.excerpt}
										category={el.categories.nodes[0].name}
									>
									</PostTile>
								)
							})}
						</HandleLoading>

						{
							data_post?.posts.pageInfo.hasNextPage &&
							<button onClick={() => {
								fetchMore({
									variables: {
										after: data_post.posts.pageInfo.endCursor
									},
									updateQuery: (prev, { fetchMoreResult }) => {
										if (!fetchMoreResult) return prev;
										return {posts: {pageInfo: {...fetchMoreResult.posts.pageInfo}, nodes: [...prev.posts.nodes, ...fetchMoreResult.posts.nodes] } };
									}
								});
							}}
							className={`${s_btns.btn_outline_white} ${s_btns.btn_fixed_width} ${s_btns.btn_arrow} ${s.more_btn}`} disabled={loading_post}>
								{loading_post ? 'Loading...' : 'Więcej'}
							</button>
						}

					</div>

				</div>


			</section>

			<ContactSection />
      		<MapSection />

		</MainLayout>
	)
};

export default Blog;
